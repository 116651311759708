// src/components/Carousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import truckImage1 from '../assets/truck1.jpg';
import truckImage2 from '../assets/truck2.jpg';
import truckImage3 from '../assets/truck3.jpg';
import './Carousel.css';

const CarouselSection = () => {
  return (
    <div className="carousel-section">
      <Carousel showThumbs={false} infiniteLoop autoPlay>
        <div className="carousel-slide">
          <img src={truckImage1} alt="Truck 1" />
        </div>
        <div className="carousel-slide">
          <img src={truckImage2} alt="Truck 2" />
        </div>
        <div className="carousel-slide">
          <img src={truckImage3} alt="Truck 3" />
        </div>
      </Carousel>
      <div className="carousel-title">
        <span className="large-title animated">Navigating Routes, Delivering Dreams</span>
      </div>
    </div>
  );
};

export default CarouselSection;
