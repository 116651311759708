// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './Components/Navbar';
import CarouselSection from './Components/Carousel';
import CardsSection from './Components/Cards';
import DescriptionSection from './Components/Description';
import ContactSection from './Components/Contact';
import Footer from './Components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path="/">
            <CarouselSection />
            <CardsSection />
            <DescriptionSection />
            <ContactSection />
            <Footer />
          </Route>
          <Route path="/services">
            <Navbar />
            <CardsSection />
            <Footer />
          </Route>
          <Route path="/about">
            <Navbar />
            <DescriptionSection />
            <Footer />
          </Route>
          <Route path="/contact">
            <Navbar />
            <ContactSection />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
