// src/components/Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h2 className="section-title">Contact Us</h2>
      <div className="contact-info">
        <p>Email: admin@weonelogistics.com</p>
        <p>Phone: (864) 528-6482</p>
        <p>Business Hours: Mon-Fri 8am to 5pm</p>
      </div>
      <form className="contact-form">
        <input type="text" placeholder="Full Name" />
        <input type="email" placeholder="Email Address" />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Contact;
