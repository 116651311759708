// src/components/Navbar.js
import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Navbar.css';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="WeOne Logistics Logo" />
      </div>
      <div className={`navbar-links ${showMenu ? 'show' : ''}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <FaBars />
      </div>
      <div className="navbar-social-icons">
        <a href="https://www.facebook.com/WeOneLogistics/">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/weone_logistics/">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/company/weone-logistics/">
          <FaLinkedin />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
