import React from 'react';
import './Cards.css'; // Import the styling for Cards
import image1 from '../assets/image1.jpg'; // Adjust the path based on your folder structure
import image2 from '../assets/image2.jpg'; // Adjust the path based on your folder structure
import image3 from '../assets/image3.jpg'; // Adjust the path based on your folder structure
import image4 from '../assets/image4.jpg'; // Adjust the path based on your folder structure

const Cards = () => {
  return (
    <div className="cards-container">
      <h2 className="cards-title">Services</h2>
      <div className="cards-row">
        {/* Card 1 */}
        <div className="card">
          <div className="card-content">
            <div className="card-image" style={{ backgroundImage: `url(${image1})` }} />
            <h3 className="card-title">Regional and Interstate Transport</h3>
            <p className="card-description">Seamless freight movement for short distances and extended routes.</p>
          </div>
        </div>
        {/* Card 2 */}
        <div className="card">
          <div className="card-content">
            <div className="card-image" style={{ backgroundImage: `url(${image2})` }} />
            <h3 className="card-title">Carrier Packet Onboarding</h3>
            <p className="card-description">Our experts handle carrier packets, ensuring you access top-notch loads.</p>
          </div>
        </div>
      </div>
      <div className="cards-row">
        {/* Card 3 */}
        <div className="card">
          <div className="card-content">
            <div className="card-image" style={{ backgroundImage: `url(${image3})` }} />
            <h3 className="card-title">Invoicing and Paperwork</h3>
            <p className="card-description">Free up your time for deliveries as we manage invoicing and paperwork.</p>
          </div>
        </div>
        {/* Card 4 */}
        <div className="card">
          <div className="card-content">
            <div className="card-image" style={{ backgroundImage: `url(${image4})` }} />
            <h3 className="card-title">24/7 Personal Dispatcher</h3>
            <p className="card-description">Your constant guide for efficient load management and operations.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
