// src/components/Description.js
import React from 'react';
import './Description.css';
import logo from '../assets/logo.png';

const Description = () => {
  return (
    <div className="description-container">
      <h2 className="section-title">About</h2>
      <div className="description-cards">
        <div className="description-card">
          <div className="description-image" style={{ backgroundImage: `url(${logo})` }}></div>
        </div>
        <div className="description-card">
          <div className="description-content">
            <p>
              At WeOne Logistics, our mission is clear: revolutionizing truck dispatch in the USA. With expert navigation
              and a commitment to efficiency, we unite businesses with seamless logistics solutions. Join us to discover
              the future of dispatching – where success meets the open road.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
